import { graphql, Link, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useLocation } from "@reach/router"

import React from "react"
import CTA from "../components/cta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo-only.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { IconBrandLinkedin, IconBrandTwitter, IconList } from "@tabler/icons"

String.prototype.hashCode = function () {
  var hash = 0
  for (var i = 0; i < this.length; i++) {
    var char = this.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      createdAt(formatString: "MMMM DD, YYYY")
      updatedAt(formatString: "MMMM DD, YYYY")
      slug
      author
      category
      blurb {
        blurb
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            title
            file {
              url
            }
          }
          ... on ContentfulCallout {
            __typename
            contentful_id
            title
            content {
              raw
            }
          }
        }
      }
      featuredImage {
        gatsbyImageData(layout: FULL_WIDTH)
        url
      }
    }
    allContentfulBlogPost(sort: { fields: updatedAt, order: DESC }, limit: 10) {
      nodes {
        contentful_id
        blurb {
          blurb
        }
        author
        category
        title
        createdAt(formatString: "MMMM DD, YYYY")
        slug
      }
    }
  }
`

const TableOfContents = ({ body }) => {
  // the body is the json object returned by the rich text field
  const headingTypes = [BLOCKS.HEADING_1, BLOCKS.HEADING_2, BLOCKS.HEADING_3]

  const headings = body.content.filter(item =>
    headingTypes.includes(item.nodeType)
  )

  const document = {
    nodeType: "document",
    content: headings,
  }

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <li className="my-2 hover:text-lilac">
            <a href={`#${node.content[0].value.hashCode()}`}>{children}</a>
          </li>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <li className="my-2 hover:text-lilac">
            <a href={`#${node.content[0].value.hashCode()}`}>{children}</a>
          </li>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <li className="pl-4 my-1 font-normal">
            <a href={`#${node.content[0].value.hashCode()}`}>{children}</a>
          </li>
        )
      },
    },
  }

  return (
    <nav>
      <ul className="text-sm text-dark-grey">
        {documentToReactComponents(document, options)}
      </ul>
    </nav>
  )
}

function PostCard({ date, title, blurb, url, category }) {
  return (
    <Link to={url}>
      <div className="max-w-3xl mx-auto p-4 md:p-6 group rounded-xl hover:shadow-xl border border-transparent hover:border-medium-grey transition duration-500">
        <p className="text-dark-grey mb-4 font-sans text-sm">
          {date} &mdash; {category}
        </p>
        <div className="group-hover:text-lilac transition font-medium text-2xl mb-4">
          {title}
        </div>
        <p className="text-dark-grey mb-6 text-sm">{blurb}</p>
      </div>
    </Link>
  )
}

const BlogPost = props => {
  const blogPost = props.data.contentfulBlogPost
  const relatedPosts = props.data.allContentfulBlogPost.nodes
  const location = useLocation()
  const currentUrl = location.href

  function createOptionsObj(links) {
    // create an entry map
    const entryMap = new Map()
    // loop through the block linked entries and add them to the map
    for (const entry of links) {
      entryMap.set(entry.contentful_id, entry)
    }

    return {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const { gatsbyImageData, title } = node.data.target
          if (!gatsbyImageData) {
            const entry = entryMap.get(node.data.target.sys.id)
            return (
              <div className="text-center">
                <GatsbyImage
                  image={entry.gatsbyImageData}
                  className="my-4"
                  title={entry.title}
                  alt={entry.title}
                />
              </div>
            )
          }
          return (
            <div className="text-center">
              <GatsbyImage
                image={gatsbyImageData}
                className="rounded-lg my-4 lg:my-12"
                title={title}
                alt={title}
              />
            </div>
          )
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          return <h2 id={node.content[0].value.hashCode()}>{children}</h2>
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return <h2 id={node.content[0].value.hashCode()}>{children}</h2>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return <h3 id={node.content[0].value.hashCode()}>{children}</h3>
        },
        [BLOCKS.HEADING_4]: (node, children) => {
          return <h4 id={node.content[0].value.hashCode()}>{children}</h4>
        },
        [BLOCKS.TABLE]: (node, children) => (
          <table>
            <tbody>{children}</tbody>
          </table>
        ),
        [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
        [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
        [INLINES.HYPERLINK]: node => {
          if (node.data.uri.includes("loom.com/embed")) {
            return (
              <iframe
                className="w-full aspect-video shadow-2xl shadow-purple-500/50 rounded-lg"
                src={node.data.uri}
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            )
          } else if (node.content[0]?.value) {
            return (
              <a
                href={node.data.uri}
                className="text-blue no-underline border-b border-b-blue/50"
              >
                {node.content[0]?.value}
              </a>
            )
          } else {
            return <a href={node.data.uri}>{node.data.uri}</a>
          }
        },
        [INLINES.EMBEDDED_ENTRY]: node => {
          // find the entry in the entryMap by ID
          const entry = entryMap.get(node.data.target.sys.id)
          return (
            <div className="p-6 md:p-8 bg-lilac-15 rounded-lg border border-lilac-40 shadow-inner">
              <h5 className="uppercase font-bold">{entry.title}</h5>
              {documentToReactComponents(JSON.parse(entry.content.raw))}
            </div>
          )
        },
      },
    }
  }

  return (
    <Layout variant="plain">
      <SEO
        title={blogPost.title}
        description={blogPost.blurb.blurb}
        image={blogPost.featuredImage.url}
        appendDomain={false}
      />

      <section>
        <div className="container mx-auto px-4 sm:px-6 py-4 sm:py-12">
          <div className="text-xs md:text-base mb-8 text-light-grey">
            <Link to="/blog" className="hover:text-lilac">
              Resources
            </Link>{" "}
            &rarr; {blogPost.category} &rarr;
          </div>
          <h1 className="text-white text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold max-w-3xl">
            {blogPost.title}
          </h1>
        </div>
      </section>

      <section className="bg-white xl:py-24">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex flex-col xl:flex-row-reverse gap-12">
            <div className="lg:w-64 order-3 xl:order-1">
              <dl className="sticky top-0 py-8 text-dark-grey">
                <dt className="text-xs">Written by</dt>
                <dd>{blogPost.author}</dd>
                <dt className="text-xs mt-4">Last Updated</dt>
                <dd>{blogPost.createdAt}</dd>
                <dt className="text-xs mt-4">Share this post</dt>
                <dd className="flex gap-1">
                  <Link
                    to={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                      currentUrl
                    )}`}
                    className="rounded-lg border border-medium-grey p-1 shadow hover:border-blue transition"
                  >
                    <IconBrandLinkedin />
                  </Link>
                  <Link
                    to={`https://twitter.com/intent/tweet?text=${currentUrl}`}
                    className="rounded-lg border border-medium-grey p-1 shadow hover:border-blue transition"
                  >
                    <IconBrandTwitter />
                  </Link>
                </dd>
              </dl>
            </div>

            <div className="flex-1 order-2">
              <article className="prose lg:prose-lg font-light xl:font-normal text-gray-600 prose-th:sticky prose-th:top-0 prose-th:bg-light-grey prose-th:text-xs">
                {documentToReactComponents(
                  JSON.parse(blogPost.body.raw),
                  createOptionsObj(blogPost.body.references)
                )}
              </article>
            </div>

            <div className="xl:w-64 order-1 xl:order-3">
              <div className="sticky top-0 xl:border-r py-8 pr-8">
                <div className="mb-4 text-lg font-semibold">Contents</div>
                <TableOfContents body={JSON.parse(blogPost.body.raw)} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-24 border-t">
        <div className="container mx-auto px-4 md:px-6 py-6">
          <h3 className="text-4xl font-bold">Read more</h3>
        </div>
        <div className="container mx-auto flex flex-col md:flex-row gap-2">
          {relatedPosts
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
            .map(post => (
              <PostCard
                key={post.contentful_id}
                date={post.createdAt}
                title={post.title}
                blurb={post.blurb.blurb}
                url={"/blog/" + post.slug}
                category={post.category}
              />
            ))}
        </div>
      </section>

      <div className="container mx-auto">
        <div className="flex items-center gap-6">
          <div className="h-px bg-gray-700 flex-1"></div>
          <img src={logo} className="h-12 mx-auto my-12" />
          <div className="h-px bg-gray-700 flex-1"></div>
        </div>
        <CTA />
      </div>
    </Layout>
  )
}

export default BlogPost
